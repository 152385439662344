import { FC } from 'react'

const Oops: FC = () => (
  <>
    <style jsx>{`
      .info-block {
        text-align: center;
      }
    `}</style>
    <div className="info-block">Oops</div>
  </>
)

export default Oops

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { Formik, Form, Field } from 'formik'
import interpolateComponents from 'interpolate-components'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { MenuItem, TextField, Checkbox } from '@virgin-core/components'
import { color } from '@virgin-core/styles'
import { authSelector } from '../../features/auth/authSlice'
import { getProfile, patchProfile, profileSelector } from '../../features/profile/profileSlice'
import { PatchableProfileItems, marketingEmailText, marketingSmsText, marketingTelText, marketingText } from '../../features/profile/types'
import { DOTCOM_PATHS, PATHS, virginRedPath } from '../../routes/paths'
import { ViewingRegion, UsaStates, UsaState } from '../../utils/constants'
import H3Header from '../../components/Typography/H3Header'
import StyledCopyAnchor from '../../components/StyledCopyAnchor'
import { Button } from '../../components/Button'
import { personaDetailsValidationSchema } from '../../utils/validationSchema'
import { getRegionDisplayName, parseDigits } from '../../utils/helperFunctions'
import { getPrepopulatedFields, isMatchingId } from '../../dataImplementation/partnerConnectIdData'
import { styles } from './PersonalDetails.styles'

type PersonalDetailsFormPropsType = {
  country: string
  firstName: string
  lastName: string
  dateOfBirth: any
  mobile: string
  postcode: string
  state: string
  termsVersion: string
  marketingEmail: boolean
  marketingSms: boolean
  marketingTel: boolean
}

const PersonalDetails = () => {
  const { REACT_APP_FEATURE_DATA_PREFILLING: dataPrefillFeature } = process.env
  const { accessToken } = useSelector(authSelector)
  const profileState = useSelector(profileSelector)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfile(accessToken))
    }
  }, [accessToken, dispatch])

  useEffect(() => {
    if (profileState?.profile?.complete) navigate(PATHS.SUCCESS)
  }, [navigate, profileState?.profile?.complete])

  const personaDetailsInitialVals: PersonalDetailsFormPropsType = {
    country: 'GB',
    firstName: '',
    lastName: '',
    dateOfBirth: null, // if dateOfBirth is defined as '' yup will throw a invalid date error
    mobile: '',
    postcode: '',
    state: '',
    // Consent is opt-out on form but opt-in in API, so reverse boolean value
    marketingEmail: true,
    marketingSms: true,
    marketingTel: true,
    termsVersion: '',
    ...(dataPrefillFeature === 'true' && isMatchingId(profileState?.profile?.email) ? getPrepopulatedFields() : undefined)
  }

  const handleSubmit = (values: PersonalDetailsFormPropsType) => {
    const payload: PatchableProfileItems = {
      channel: 'RED_SIGNUP',
      country: values.country,
      dateOfBirth: format(values.dateOfBirth, 'yyyy-MM-dd'),
      firstName: values.firstName,
      lastName: values.lastName,
      marketingEmail: values.marketingEmail,
      marketingEmailText,
      marketingSms: values.marketingSms,
      marketingSmsText,
      marketingTel: values.marketingTel,
      marketingTelText,
      marketingText,
      mobile: parseDigits(values.mobile),
      postcode: values.postcode,
      state: values.state,
      termsVersion: values.termsVersion,
      notifications: false,
      linkedAccountsState: { VAA: false },
      complete: true
    }

    dispatch(patchProfile(accessToken, payload))
  }

  return (
    <>
      <style jsx>{styles}</style>
      {profileState.loading ? (
        <div className="info-block">
          Loading...
          <br />
          {!accessToken && <>No accessToken available to app - restart app flow via /?token=[your.lovely.token]</>}
        </div>
      ) : (
        <>
          {accessToken ? (
            <div className="card-wrapper">
              <div className="card">
                <Formik
                  validateOnBlur={true}
                  validateOnChange={true}
                  initialValues={personaDetailsInitialVals}
                  validationSchema={personaDetailsValidationSchema}
                  onSubmit={handleSubmit}>
                  {({ values, setFieldValue, touched, errors, handleBlur }) => {
                    const isUS = values.country === ViewingRegion.US
                    return (
                      <Form data-testid="onboarding-personal-details">
                        <H3Header
                          textTransform="none"
                          color={color.brandPrimary}
                          marginTop={{ mobile: 0 }}
                          marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
                          Almost done...
                        </H3Header>
                        <p className="instructions">Fill in a few more details to join Virgin Red.</p>
                        <section className="personal-details-section">
                          <h4 className="section-header">Your details</h4>
                          <Field
                            data-testid="onboarding.personalDetails.country"
                            component={TextField}
                            id="country"
                            name="country"
                            as="select"
                            label="Country*"
                            placeholder="e.g. United Kingdom"
                            value={values.country}
                            error={touched.country && !!errors.country}
                            helperText={touched.country && errors.country}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                              setFieldValue('country', event.target.value)
                            }}
                            select
                            style={{ pointerEvents: 'all' }}
                            iconColor={color.textStandard}
                            fullWidth>
                            {Object.keys(ViewingRegion).map((enumKey) => (
                              <MenuItem
                                data-testid={`menu-item-${enumKey}`}
                                id={`menu-item-${enumKey}`}
                                key={`menu-item-${enumKey}`}
                                value={enumKey}>
                                {getRegionDisplayName(enumKey as ViewingRegion)}
                              </MenuItem>
                            ))}
                          </Field>
                          <Field
                            data-testid="onboarding.personalDetails.firstName"
                            component={TextField}
                            id="firstName"
                            name="firstName"
                            type="text"
                            label="First name*"
                            placeholder="Richard"
                            value={values.firstName}
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('firstName', event.target.value)}
                            onBlur={handleBlur}
                            fullWidth
                          />
                          <Field
                            data-testid="onboarding.personalDetails.lastName"
                            component={TextField}
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="Last name*"
                            placeholder="Branson"
                            value={values.lastName}
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('lastName', event.target.value)}
                            onBlur={handleBlur}
                            fullWidth
                          />
                          <div className="dob-field">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                data-testid="onboarding.personalDetails.dateOfBirth"
                                className="dob-field-picker"
                                id="date-picker-dialog"
                                name="dateOfBirth"
                                label="Date of birth*"
                                placeholder={isUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
                                value={values.dateOfBirth}
                                error={touched.dateOfBirth && !!errors.dateOfBirth}
                                helperText={touched.dateOfBirth && errors.dateOfBirth}
                                onChange={(value) => setFieldValue('dateOfBirth', value)}
                                onBlur={handleBlur}
                                format={isUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                fullWidth
                              />
                            </MuiPickersUtilsProvider>
                            <div className="dob-note">You must be 18+ to join Virgin Red</div>
                          </div>
                          <Field
                            data-testid="onboarding.personalDetails.mobile"
                            component={TextField}
                            id="mobile"
                            name="mobile"
                            type="tel"
                            label={isUS ? 'Cell number*' : 'Mobile number*'}
                            placeholder={isUS ? '(XXX) XXX-XXXX' : '07XXX XXXXXX'}
                            value={values.mobile}
                            error={touched.mobile && !!errors.mobile}
                            helperText={touched.mobile && errors.mobile}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('mobile', event.target.value)}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: isUS ? 10 : 11 }}
                            fullWidth
                          />
                          {isUS && (
                            <>
                              <Field
                                data-testid="onboarding.personalDetails.state"
                                component={TextField}
                                id="state"
                                name="state"
                                as="select"
                                label="State*"
                                placeholder="State"
                                value={values.state}
                                error={touched.state && !!errors.state}
                                helperText={touched.state && errors.state}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setFieldValue('state', event.target.value)}
                                onBlur={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                  handleBlur({ ...event, target: { ...event.target, name: 'state' } })
                                }
                                select
                                iconColor={color.textStandard}
                                style={{ pointerEvents: 'all' }}
                                fullWidth>
                                {UsaStates.map((state: UsaState) => (
                                  <MenuItem key={`menu-item-${state.name}`} id={`menu-item-${state.name}`} value={state.code}>
                                    {state.name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </>
                          )}
                          <Field
                            data-testid="onboarding.personalDetails.postcode"
                            component={TextField}
                            className="postcode-field"
                            id="postcode"
                            name="postcode"
                            type="text"
                            label={isUS ? 'Zip code*' : 'Post code*'}
                            placeholder={isUS ? '90210' : 'W2 6ET'}
                            value={values.postcode}
                            error={touched.postcode && !!errors.postcode}
                            helperText={touched.postcode && errors.postcode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('postcode', event.target.value)}
                            onBlur={handleBlur}
                            tooltipText={
                              isUS
                                ? 'We’ll use your zip code to offer you tailored rewards and for the security of your account'
                                : 'We’ll use your postcode to offer you tailored rewards and for the security of your account'
                            }
                            iconColor={color.textStandard}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            fullWidth
                          />
                        </section>
                        <section className="communication-preferences-section">
                          <h4 className="section-header">Communication preferences</h4>
                          <p className="instructions">
                            We&apos;ll keep in touch with you so you&apos;ll be the first to discover new offers, rewards and ways to earn
                            Virgin Points.
                            <br />
                            <br />
                            Check the boxes if you&apos;d prefer NOT to be contacted by:
                          </p>
                          <div className="checkbox-wrapper">
                            <Checkbox
                              data-testid="checkbox-email"
                              name="marketingEmail"
                              label="Not via email"
                              checked={!values.marketingEmail}
                              onChange={(_, checked: boolean) => setFieldValue('marketingEmail', !checked)}
                            />
                            <Checkbox
                              data-testid="checkbox-sms"
                              name="marketingSms"
                              label="Not via SMS"
                              checked={!values.marketingSms}
                              onChange={(_, checked: boolean) => setFieldValue('marketingSms', !checked)}
                            />
                            <Checkbox
                              data-testid="checkbox-tel"
                              name="marketingTel"
                              label="Not via telephone"
                              checked={!values.marketingTel}
                              onChange={(_, checked: boolean) => setFieldValue('marketingTel', !checked)}
                            />
                          </div>
                          <p className="instructions">You can always change these later in your Virgin Red account settings.</p>
                        </section>
                        <section className="terms-section">
                          <h4 className="section-header">Our Terms &amp; Conditions</h4>
                          <p className="instructions">
                            When you use Virgin Red, we use the information to make your experience better. This means sharing it with
                            Virgin Red partners you&apos;ve interacted with and getting information back from them.
                            <br />
                            <br />
                            Our&nbsp;
                            <StyledCopyAnchor to={DOTCOM_PATHS.HOME + virginRedPath + PATHS.PRIVACY} target external>
                              Privacy Policy&nbsp;
                            </StyledCopyAnchor>
                            tells you more about how we collect, use, share and protect your personal information.
                            <br />
                            <br />
                            We use the information to make your experience better and show you the kind of rewards you&apos;ll love.
                          </p>
                          <div className="checkbox-wrapper">
                            <Checkbox
                              data-testid="checkbox-terms"
                              name="termsVersion"
                              // @ts-ignore
                              label={interpolateComponents({
                                mixedString:
                                  "By ticking this box you're also agreeing to the {{tcLink}}Virgin Red Programme Terms and Conditions{{/tcLink}}.",
                                components: {
                                  tcLink: (
                                    <StyledCopyAnchor to={DOTCOM_PATHS.HOME + virginRedPath + PATHS.TERMS_AND_CONDITIONS} target external />
                                  )
                                }
                              })}
                              checked={values.termsVersion === '1'}
                              onChange={(_, checked: boolean) => {
                                touched.termsVersion = true
                                setFieldValue('termsVersion', checked ? '1' : '')
                              }}
                            />
                            {touched.termsVersion && errors.termsVersion && <div className="terms-error">{errors.termsVersion}</div>}
                          </div>
                        </section>
                        <div className="button-wrapper">
                          <Button
                            dataAttributes={{ testid: 'onboarding-personal-details-button' }}
                            text="Create account"
                            isPrimary
                            buttonType="submit"
                            onClick={() => {}}
                            display="inline-block"
                            isFullWidth={false}
                          />
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="info-block">Something went wrong. Please try logging in again.</div>
          )}
        </>
      )}
    </>
  )
}

export default PersonalDetails

import { Route, Routes } from 'react-router-dom'
import Init from '../pages/init/Init'
import Guard from '../pages/init/Guard'
import Oops from '../pages/error/Oops'
import Success from '../pages/success/Success'
import NotFound from '../pages/error/NotFound'
import { PATHS } from './paths'
import PersonalDetails from '../pages/personal-details/PersonalDetails'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.INIT} element={<Init />} />
      <Route path={PATHS.GUARD} element={<Guard />} />
      <Route path={PATHS.PERSONAL_DETAILS} element={<PersonalDetails />} />
      <Route path={PATHS.SUCCESS} element={<Success />} />
      <Route path={PATHS.OOPS} element={<Oops />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

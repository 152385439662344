import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { authSelector } from '../../features/auth/authSlice'
import { profileSelector, getProfile } from '../../features/profile/profileSlice'
import { PATHS } from '../../routes/paths'
import { LoadSpinner } from '../../components/LoadSpinner'
import { media } from '@virgin-core/styles'

const Guard = () => {
  const { accessToken } = useSelector(authSelector)
  const profileState = useSelector(profileSelector)
  const { authState } = useSelector(authSelector)

  const { REACT_APP_AUTH0_CONTINUE_URL } = process.env

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfile(accessToken))
    }
  }, [dispatch, accessToken])

  useEffect(() => {
    if (!profileState?.loading) {
      if (profileState?.profile?.complete) {
        window.location.href = `${REACT_APP_AUTH0_CONTINUE_URL}?state=${authState}`
      } else {
        navigate(PATHS.PERSONAL_DETAILS)
      }
    }
  }, [REACT_APP_AUTH0_CONTINUE_URL, authState, navigate, profileState?.loading, profileState?.profile?.complete])

  return (
    <>
      <style jsx>{`
        .loading p {
          text-align: center;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600 !important;
          margin: 0 0 24px;
        }
        @media ${media.tabletAndHigher} {
          .loading p {
            font-size: 28px;
            line-height: 32px;
          }
        }
        @media ${media.desktopAndHigher} {
          .loading p {
            margin-bottom: 32px;
          }
        }
      `}</style>
      <div className="loading">
        <p>Nearly there...</p>
        <LoadSpinner />
      </div>
    </>
  )
}

export default Guard

import { FC } from 'react'
import { color } from '@virgin-core/styles'

interface LoadSpinnerProps {
  position?: 'static' | 'absolute'
  verticalMargin?: number
}

export const LoadSpinner: FC<LoadSpinnerProps> = ({ position = 'static', verticalMargin = 0 }) => (
  <>
    <style jsx>{`
      @keyframes opacity {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .loader {
        border: 4px solid ${color.secondaryButtonBorder};
        border-top: 4px solid ${color.brandPrimary};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        animation: spin 1s infinite linear, 1s ease-out 0.3s opacity both;
        margin: ${verticalMargin}px auto;
        position: ${position};
        left: calc(50% - 16px);
        top: calc(50% - 16px);
      }
    `}</style>
    <div className="loader"></div>
  </>
)

import { FC } from 'react'
import cx from 'classnames'
import { color, FontFamilies, media } from '@virgin-core/styles'
import { mapDataAttributes } from '../../utils/dataAttributes'
import { LoadSpinner } from '../LoadSpinner'
import { ButtonProps } from './types'

const Button: FC<ButtonProps> = ({
  dataAttributes,
  text,
  onClick,
  isPrimary,
  hasBorder,
  display,
  disabled,
  minWidthAuto = false,
  loading,
  isFullWidth = true,
  buttonType
}) => {
  const borderStyle = {
    border: hasBorder ? `2px solid ${color.white}` : `solid 1px ${isPrimary ? color.brandPrimary : color.secondaryButtonBorder}`,
    hoverBorderColor: hasBorder ? color.white : color.brandPrimary
  }

  const dataAttributesProps = mapDataAttributes(dataAttributes ?? {})
  return (
    <>
      <style jsx>{`
        .button {
          background-color: ${isPrimary ? color.brandPrimary : color.white};
          border: ${borderStyle.border};
          border-radius: 8px;
          color: ${isPrimary ? color.white : color.brandPrimary};
          cursor: ${loading ? 'default' : 'pointer'};
          display: ${display ?? 'block'};
          font-family: ${FontFamilies.barlow};
          font-size: 15px;
          font-weight: bold;
          height: 48px;
          line-height: 1.5;
          min-width: ${minWidthAuto ? 'auto' : '140px'};
          padding: 0 16px;
          text-align: center;
          transition: 150ms ease-out;
          width: ${!isFullWidth ? 'auto' : '100%'};
        }
        .button:disabled {
          opacity: 0.2;
          cursor: default;
        }
        @media ${media.tabletAndHigher} {
          .button:hover {
            border-color: ${borderStyle.hoverBorderColor};
          }
          .button--with-border:hover {
            background-color: ${isPrimary ? color.redHover : color.secondaryRedButtonHover};
          }
        }
      `}</style>
      <button
        {...dataAttributesProps}
        type={buttonType || 'button'}
        className={cx('button', { 'button--with-border': hasBorder })}
        disabled={!loading && disabled}
        onClick={loading ? () => {} : onClick}>
        {loading ? <LoadSpinner /> : text}
      </button>
    </>
  )
}

export { Button }

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  accessToken: string
  authState: string
  errors: null | string
  settingToken: boolean
}

const initialState: AuthState = {
  accessToken: '',
  authState: '',
  errors: null,
  settingToken: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload
    },
    setAuthState: (state, { payload }: PayloadAction<string>) => {
      state.authState = payload
    },
    assignSettingToken: (state, { payload }: PayloadAction<boolean>) => {
      state.settingToken = payload
    }
  }
})

// actions
export const { setErrors, setAccessToken, setAuthState, assignSettingToken } = authSlice.actions

export default authSlice.reducer

export const authSelector = (state: { auth: AuthState }) => state.auth

import { FC, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/paths'
import { media } from '@virgin-core/styles'

import { assignSettingToken, authSelector, setAccessToken, setAuthState } from '../../features/auth/authSlice'

import { LoadSpinner } from '../../components/LoadSpinner'
import { setLoading } from '../../features/profile/profileSlice'

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const Init: FC = () => {
  const [warning, setWarning] = useState<boolean>(false)
  const warningFunc = setTimeout(() => setWarning(true), 5000)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { settingToken } = useSelector(authSelector)

  const query = useQuery()
  const tokenInUrl = query.get('token')
  const stateInUrl = query.get('state')

  useEffect(() => {
    dispatch(assignSettingToken(true))
  }, [dispatch])

  useEffect(() => {
    if (settingToken && tokenInUrl && stateInUrl) {
      dispatch(setAccessToken(tokenInUrl))
      dispatch(setAuthState(stateInUrl))
      dispatch(assignSettingToken(false))
      dispatch(setLoading(true))
      navigate(PATHS.GUARD)
    }
    return () => clearTimeout(warningFunc)
  }, [dispatch, navigate, settingToken, stateInUrl, tokenInUrl, warningFunc])

  return (
    <>
      <style jsx>{`
        .loading p {
          text-align: center;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600 !important;
          margin: 0 0 24px;
        }
        @media ${media.tabletAndHigher} {
          .loading p {
            font-size: 28px;
            line-height: 32px;
          }
        }
        @media ${media.desktopAndHigher} {
          .loading p {
            margin-bottom: 32px;
          }
        }
      `}</style>
      <div className="loading">
        {warning ? (
          <p>Something went wrong. Please try logging in again.</p>
        ) : (
          <>
            <p>Nearly there...</p>
            <LoadSpinner />
          </>
        )}
      </div>
    </>
  )
}

export default Init

import { FC } from 'react'
import { FontFamilies, media } from '@virgin-core/styles'
import { DOTCOM_PATHS } from '../../routes/paths'
import StyledCopyAnchor from '../../components/StyledCopyAnchor'
import H1Header from '../../components/Typography/H1Header'
import TextWithSymbol from '../../components/Typography/TextWithSymbol'
import LeadText from '../../components/Typography/LeadText'

const NotFound: FC = () => {
  return (
    <>
      <style jsx>{`
        :global(.header-one) {
          margin-top: 0 !important;
        }
        @media ${media.desktopAndHigher} {
          .content-wrapper {
            margin-bottom: 80px;
          }
        }
      `}</style>
      <H1Header textAlign="center" fontFamily={FontFamilies.barlow}>
        <TextWithSymbol text={'Whoops.'} />
      </H1Header>
      <div className="content-wrapper" data-testid="404-content">
        <LeadText>
          We’ve looked everywhere (even down the back of Richard’s sofa) and we can’t find this page. Our IT guys call it a 404 error.
          Please go back to your previous page or check out the{' '}
          <StyledCopyAnchor to={DOTCOM_PATHS.RED_HOME} external>
            Virgin Red home page
          </StyledCopyAnchor>
          .
        </LeadText>
      </div>
    </>
  )
}

export default NotFound

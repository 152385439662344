export const PATHS = {
  SUCCESS: '/success',
  OOPS: '/oops',
  INIT: '/',
  GUARD: '/profile-check',
  PERSONAL_DETAILS: '/personal-details',
  TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  PRIVACY: `/privacy-policy`
}

export const virginRedPath = '/virgin-red'

const dotcom = process.env.REACT_APP_DOTCOM_URL

export const DOTCOM_PATHS = {
  HOME: `${dotcom}`,
  RED_HOME: `${dotcom}/virgin-red`
}

import { useSelector } from 'react-redux'
import { color } from '@virgin-core/styles'
import { profileSelector } from '../../features/profile/profileSlice'
import { authSelector } from '../../features/auth/authSlice'
import H3Header from '../../components/Typography/H3Header'
import { Button } from '../../components/Button'
import { styles } from './Success.styles'

const Success = () => {
  const profileState = useSelector(profileSelector)
  const { authState } = useSelector(authSelector)
  const { REACT_APP_AUTH0_CONTINUE_URL } = process.env

  return (
    <>
      <style jsx>{styles}</style>
      {profileState?.profile ? (
        <div className="card-wrapper">
          <div className="card">
            <div data-testid="onboarding-success" className="success-container">
              <H3Header
                textTransform="none"
                color={color.brandPrimary}
                marginTop={{ mobile: 0 }}
                marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
                Welcome to the club!
              </H3Header>
              <p className="success-intro-text">
                Congratulations on creating an account and becoming a Virgin Red member. You can now get started earning and spending Virgin
                Points.
              </p>
              <h4>Your Virgin Red ID</h4>
              <p className="copy">
                Be sure to keep this handy, you might sometimes be asked for it when earning and spending Virgin Points with our partners.
              </p>
              <div className="profile-id-block">{profileState.profile?.redUserId}</div>
              <p className="instructions">
                Don&apos;t worry we know this is too long to remember! It&apos;s also on its way to your inbox.
              </p>
              <p className="copy">
                Alternatively you can find it on the Virgin Red app or website, just head to the personal details section in your account.
              </p>
              <div className="button-wrapper">
                <Button
                  dataAttributes={{ testid: 'onboarding.welcome.skipButton' }}
                  text="Continue"
                  isPrimary
                  buttonType="submit"
                  onClick={() => {
                    window.location.href = `${REACT_APP_AUTH0_CONTINUE_URL}?state=${authState}`
                  }}
                  display="inline-block"
                  isFullWidth
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="info-block">Something went wrong. Please try logging in again.</div>
      )}
    </>
  )
}

export default Success

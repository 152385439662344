import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../'
import { getProfileAPI, patchProfileAPI } from './api'
import { Profile, PatchableProfileItems } from './types'

export interface ProfileState {
  errors: any
  profile: Profile | null
  loading: boolean
}

const initialState: ProfileState = {
  errors: null,
  profile: null,
  loading: false
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload
    },
    setProfile: (state, { payload }: PayloadAction<Partial<Profile>>) => {
      state.profile = payload
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    }
  }
})

export const patchProfile = (accessToken: string, payload: PatchableProfileItems): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const res = await patchProfileAPI({ accessToken, payload })
      console.log(res)
      dispatch(setProfile(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setErrors(error))
      dispatch(setLoading(false))
    }
  }
}

export const getProfile = (accessToken: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const res = await getProfileAPI({ accessToken })
      console.log(res)
      dispatch(setProfile(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setErrors(error))
      dispatch(setLoading(false))
    }
  }
}

// actions
export const { setErrors, setLoading, setProfile } = profileSlice.actions

export default profileSlice.reducer

export const profileSelector = (state: { profile: ProfileState }) => state.profile

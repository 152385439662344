import axios from 'axios'
import { PatchableProfileItems } from './types'

export const getProfileAPI = async ({ accessToken }: { accessToken: string }) => {
  const { REACT_APP_API_URL } = process.env
  if (REACT_APP_API_URL) {
    try {
      const resp = await axios.get(`${REACT_APP_API_URL}/profiles`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      return resp.data
    } catch (e) {
      console.log(e)
      // TODO: implement refresh & 400-range responses
    }
  } else {
    throw new Error('getProfile: env var not available')
  }
  return undefined
}

export const patchProfileAPI = async ({ payload, accessToken }: { payload: PatchableProfileItems; accessToken: string }) => {
  const { REACT_APP_API_URL } = process.env
  if (REACT_APP_API_URL) {
    try {
      const resp = await axios.patch(`${REACT_APP_API_URL}/profiles/v2`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      return resp.data
    } catch (e) {
      console.log(e)
    }
  } else {
    throw new Error('patchProfile: env var not available')
  }
  return undefined
}

import css from 'styled-jsx/css'
import { color, media } from '@virgin-core/styles'

export const styles = css`
  .info-block {
    text-align: center;
  }

  @keyframes in {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .card {
    position: relative;
    border-radius: 8px;
    animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
  }

  @media ${media.desktopAndHigher} {
    .card-wrapper {
      max-width: 648px;
      margin: 0 auto;
    }

    .card {
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #e3e3e3;
      background: #fff;
      padding: 56px 80px 64px;
    }
  }

  :global(.header-three) {
    font-size: 28px !important;
    line-height: 32px !important;
    margin-bottom: 16px !important;
  }

  .success-intro-text {
    margin: 0 0 32px;
    font-size: 18px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    font-weight: 600 !important;
  }

  .copy {
    margin: 8px 0 16px;
  }

  p.instructions {
    font-weight: 600;
  }

  .profile-id-block {
    background-color: ${color.darkPinkBackground};
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 64px;
    height: 64px;
  }

  .button-wrapper {
    margin-top: 40px;
  }

  @media ${media.mobileAndLower} {
    .button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
`

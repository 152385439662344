import React from 'react'
import ReactDOM from 'react-dom'
import { ThunkAction } from 'redux-thunk'
import { Provider } from 'react-redux'
import { configureStore, Action } from '@reduxjs/toolkit'
import App from './App'
import reportWebVitals from './reportWebVitals'
import authSliceReducer from './features/auth/authSlice'
import profileSliceReducer, { ProfileState } from './features/profile/profileSlice'

export type AppThunk = ThunkAction<void, ProfileState, unknown, Action<string>>

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    profile: profileSliceReducer
  },
  devTools: process.env.REACT_APP_IS_PRODUCTION === 'true' ? false : true
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

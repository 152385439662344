import { FC } from 'react'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'
import { color } from '@virgin-core/styles'
import { DOTCOM_PATHS } from '../../routes/paths'
import CancelButton from './CancelButton'
import VirginRedLogo from '../VirginRedLogo'
import Cookies from 'js-cookie'

interface TopBarNavProps {
  redirectHome?: () => void
  activeSectionSelected?: () => void
  activeParent?: string
  linkCentreLogo?: boolean
}

const TopBarNav: FC<TopBarNavProps> = ({ linkCentreLogo = true }) => {
  const connectFailUrl = Cookies.get('onConnectFailUrl')

  return (
    <>
      <style jsx>{`
        .parent-container {
          border-bottom: solid 1px #eee;
        }
        :global(.top-bar-nav) {
          justify-content: flex-end;
          align-items: center;
        }
        :global(.top-bar-nav) {
          position: relative;
          background: ${color.white};
          height: 64px;
        }
        :global(.logo-container) {
          display: flex;
          text-align: center;
          justify-content: center;
        }
        :global(.cancel-button-container) {
          display: flex;
          justify-content: flex-end;
        }

        :global(.cancel-button-container) a {
          text-decoration: none;
        }
      `}</style>
      <div className="parent-container">
        <GridWrapper expanded>
          <GridContainer className="top-bar-nav">
            <GridItem xs={4} className="logo-container">
              {linkCentreLogo ? (
                <a href={DOTCOM_PATHS.HOME}>
                  <VirginRedLogo />
                </a>
              ) : (
                <VirginRedLogo />
              )}
            </GridItem>
            <GridItem xs={4} className="cancel-button-container">
              <a href={connectFailUrl ?? DOTCOM_PATHS.RED_HOME}>
                <CancelButton />
              </a>
            </GridItem>
          </GridContainer>
        </GridWrapper>
      </div>
    </>
  )
}

export default TopBarNav

import { UsaStateCodes } from '../../utils/constants'

export interface OnboardingNavTargets {
  CommunicationPreferences: string
  PersonalDetails: string
  Welcome: string
  TermsAndConditions: string
  LinkVaa: string
  AutoLinkPartner: string
}

export type OnboardingState = keyof OnboardingNavTargets

export interface AppState {
  onboarding?: OnboardingState
}

export interface CoreProfile {
  redUserId?: string
  email?: string
  emailVerified?: boolean
  processingRestricted?: boolean
  profilingRestricted?: boolean
  mobileVerified?: boolean
  createdAt?: string
  updatedAt?: string
  appState?: AppState
  memberSupplierIds?: { TWILIO?: string; TEALIUM?: string }
  previewRegionUS?: boolean
  restrictAlcoholGambling?: boolean
  defaultLocale?: string
}

export interface PatchableProfileItems {
  accountLinked?: boolean
  firstName?: string
  lastName?: string
  mobile?: string
  country?: string
  state?: typeof UsaStateCodes | string
  postcode?: string
  dateOfBirth?: string
  termsVersion?: string
  marketingEmail?: boolean
  marketingEmailText?: typeof marketingEmailText
  marketingSms?: boolean
  marketingSmsText?: typeof marketingSmsText
  marketingTel?: boolean
  marketingTelText?: typeof marketingTelText
  marketingText?: typeof marketingText
  optOutPrefAlcohol?: boolean
  optOutPrefAlcoholText?: string
  optOutPrefGambling?: boolean
  optOutPrefGamblingText?: string
  complete?: boolean
  notifications?: boolean
  channel?: 'RED_SIGNUP'
  linkedAccountsState?: { VAA: false }
}

export type Profile = CoreProfile & PatchableProfileItems

export const marketingText =
  "We'll keep in touch with you so you'll be the first to discover new offers, rewards and ways to earn Virgin Points.\nCheck the boxes if you'd prefer NOT to be contacted by:"
export const marketingTelText = 'Not via telephone'
export const marketingSmsText = 'Not via SMS'
export const marketingEmailText = 'Not via email'

import * as Yup from 'yup'
import { subYears } from 'date-fns'
import { ViewingRegion } from './constants'

export const nameRegex = /^[a-zA-ZÀ-ž]+[ '\-.]?[a-zA-ZÀ-ž]+$/

export const personaDetailsValidationSchema = Yup.object({
  firstName: Yup.string()
    .matches(nameRegex, 'Invalid name entered')
    .required('Please provide your first name')
    .max(30, 'Must be 30 characters or less'),
  lastName: Yup.string()
    .matches(nameRegex, 'Invalid name entered')
    .required('Please provide your last name')
    .max(30, 'Must be 30 characters or less'),
  dateOfBirth: Yup.date()
    .required('Please provide your date of birth')
    .nullable()
    .max(subYears(new Date(), 18), 'You have to be 18 years or older to register')
    .typeError('Invalid date'),
  mobile: Yup.string().test('mobile-regex', function (value: any) {
    const regex = new RegExp(getMobileRegexByCountry(this.parent.country))
    return !regex.test(value)
      ? this.createError({
          message: `${
            this.parent.country === ViewingRegion.US ? 'Please provide a valid US cell number' : 'Please provide a valid UK mobile number'
          }`,
          path: 'mobile'
        })
      : true
  }),
  postcode: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .test('postcode-regex', function (value: any) {
      const regex = new RegExp(getPostCodeRegexByCountry(this.parent.country))
      return !regex.test(value)
        ? this.createError({
            message: `${
              this.parent.country === ViewingRegion.US ? 'Please provide a valid US zip code' : 'Please provide a valid UK post code'
            }`,
            path: 'postcode'
          })
        : true
    }),
  state: Yup.string().when('country', {
    is: ViewingRegion.US,
    then: Yup.string().required('Please provide your state')
  }),
  termsVersion: Yup.string().required('Please accept our Terms & Conditions to continue'),
  country: Yup.string().required('Country is Required')
})

export const getPostCodeRegexByCountry = (country?: string) => {
  switch (country) {
    case ViewingRegion.US:
      return /^([0-9]{5})(?:-([0-9]{4}))?$/
    // the default case is ViewingRegion.GB
    default:
      return /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/
  }
}

export const getMobileRegexByCountry = (country?: string) => {
  switch (country) {
    case ViewingRegion.US:
      return /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
    // the default case is ViewingRegion.GB
    default:
      return /^((\+44\s?|0)7([12345789]\d{2}|624)\s?\d{3}\s?\d{3})$/
  }
}

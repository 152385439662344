import { FC } from 'react'
import { color } from '@virgin-core/styles'

const CancelButton: FC = () => (
  <>
    <style jsx>{`
      .cancel-button {
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        position: relative;
        align-items: center;
        user-select: none;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        color: ${color.brandPrimary};
        border: 1px solid ${color.brandPrimary};
        background-color: ${color.white};
        height: 32px;
        padding: 0 24px;
        font-family: Barlow, sans-serif;
        font-weight: bold;
        line-height: 1.3;
        border-radius: 8px;
        text-transform: none;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    `}</style>
    <div className="cancel-button">Cancel</div>
  </>
)
export default CancelButton

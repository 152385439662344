import css from 'styled-jsx/css'
import { color, FontFamilies, media } from '@virgin-core/styles'

export const styles = css`
  :global(*) {
    box-sizing: border-box;
  }

  :global(html, body) {
    height: 100%;
    margin: 0;
  }

  :global(body) {
    color: ${color.textStandard};
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    overflow-x: hidden;
  }

  .app-content {
    padding: 32px 0 40px;
  }

  @media ${media.tabletAndHigher} {
    .app-content {
      padding-top: 56px;
    }
  }

  @media ${media.desktopAndHigher} {
    .app-content {
      padding: 72px 0 120px;
    }
  }
`

import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GridWrapper, GridContainer, GridItem, RedAppThemeProvider } from '@virgin-core/components'
import { AppRoutes } from './routes/routes'
import TopBarNav from './components/TopBarNav'
import { styles } from './App.styles'

const App: FC = () => {
  return (
    <>
      <style jsx>{styles}</style>
      <RedAppThemeProvider>
        <div className="app-header">
          <TopBarNav linkCentreLogo={false} />
        </div>
        <div className="app-content">
          <GridWrapper>
            <GridContainer className="app-container" justifyContent="center">
              <GridItem xs={12} sm={8}>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </GridItem>
            </GridContainer>
          </GridWrapper>
        </div>
      </RedAppThemeProvider>
    </>
  )
}

export default App
